(function (angular, _, undefined) {
    "use strict";
    var res = angular.module("mikesResume");

    res.controller("mikesRes.ctrl", ["$scope", "$http", "$filter", "$localStorage", "ResumeService",
        function ($scope, $http, $filter, $localStorage, Resume) {
        var vm = this;

        if (typeof $localStorage.resume !== "object") {
            $localStorage.resume = {};
        }
        vm.resume = $localStorage.resume;

        Resume.getResume()
            .then(function () {
                vm.resume = $localStorage.resume;
            }, function (err) {
                console.error("Error: " + err);
            });

    }]);

})(angular, _);
